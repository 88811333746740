import Vue from 'vue'
import InstantSearch from 'vue-instantsearch';
import algoliasearch from 'algoliasearch/lite';

Vue.use(InstantSearch)

export default ({ app }, inject) => {
    // Inject $hello(msg) in Vue, context and store.
    inject('algolia', algoliasearch(
        '4TGO5YZLFC',
        '5d5e131464e99baba19d629851c0d61a',
    ))
}