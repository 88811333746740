



































































































































































































































































































































































































































































































































































































































































import { SfOverlay, SfHeader, SfButton, SfBadge } from "@storefront-ui/vue";

import {
  computed,
  watch,
  ref,
  defineComponent,
  useRouter,
  useContext,
  // onMounted,
  useFetch,
  // toRef,
} from "@nuxtjs/composition-api";
import HeaderNavigation from "~/components/Header/Navigation/HeaderNavigation.vue";
import { useCategory } from "~/modules/catalog/category/composables/useCategory";
import {
  useUiHelpers,
  useUiState,
  useContent,
  wntrUseUser,
} from "~/composables";
import { wntrUseCart } from "~/composables";
import { WntrCart } from "~/modules/GraphQL/types";
import { useWntrCartStore } from "~/composables/wntrUseCart/useWntrCartStore";
import { useWntrWishlistStore } from "~/composables/wntrUseCart/useWntrWishlistStore";

import type { CategoryTree, ProductInterface } from "~/modules/GraphQL/types";
// import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from "~/components/General/SvgImage.vue";
import { useTopBar } from "./TopBar/useTopBar";

import type {
  MainNavigationItem,
  WntrNavCategory,
  WntrWishlistItem
} from "~/modules/GraphQL/types";

import axios from "axios";
import { Logger } from "~/helpers/logger";

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    SfOverlay,
    // HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    CurrencySelector: () => import("~/components/CurrencySelector.vue"),
    StoreSwitcher: () => import("~/components/StoreSwitcher.vue"),
    SearchBar: () => import("~/components/Header/SearchBar/SearchBar.vue"),
    SearchResults: () =>
      import(
        /* webpackPrefetch: true */ "~/components/Header/SearchBar/SearchResults.vue"
      ),
  },
  setup(props) {
    const { loadSettings, getNav } = useContent();
    // const product = toRef(props, 'product');

    const mainNavigation = ref<MainNavigationItem[] | null>(null);

    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleLoginModal } =
      useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isUserLoggedIn, refreshToken } = wntrUseUser();

    const { categories: categoryList, load: categoriesListLoad } =
      useCategory();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const categoryTree = ref<CategoryTree[]>([]);

    const handleAccountClick = async (e) => {
      e.preventDefault();
      if ((await isUserLoggedIn()) === true) {
        await router.push({ name: "account" });
      } else {
        toggleLoginModal();
      }
    };

    const accountIcon = computed(() =>
      isUserLoggedIn ? "profile_fill" : "profile"
    );

    const wntrNav = ref<WntrNavCategory[] | null>(null);

    useFetch(async () => {
      
      wntrNav.value = await getNav();

      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children.filter(
        (category) => category.include_in_menu
      );

    });

    const { 
      addToCart,
      createGuestCart,
      getUserCart,
      getWishlist,
      setQty
    } = wntrUseCart();

    const wntrCart = ref<WntrCart | null>(null);
    const cartStore = useWntrCartStore();
    const wishlistStore = useWntrWishlistStore();
    const wntrWishlist = ref<WntrWishlistItem[] | null>(null);
    // if (localStorage.getItem('wntrWishlist') === null) localStorage.setItem('wntrWishlist', JSON.stringify(wntrWishlist)) 

    const getWishlistAction = async (token: string) => {

      if (token !== '') {
        wntrWishlist.value = await getWishlist({
          token: token
        });
      } else {
        
        if (localStorage.getItem('wntrWishlist') !== null && localStorage.getItem('wntrWishlist') !== '') {
          wntrWishlist.value = JSON.parse(localStorage.getItem('wntrWishlist'));
        }
      }

      wishlistStore.$patch((state) => {
        state.wntrWishlist = wntrWishlist.value;
      });
    }

    return {
      loadSettings,
      cartStoreNumOfItems: computed(() => {
        return cartStore.$state?.wntrCart?.item_count ?? 0;
      }),
      cartStoreTotal: computed(() => {
        if ( cartStore && cartStore.$state && cartStore.$state.wntrCart ) {
          // return (parseFloat(cartStore.$state.wntrCart.subtotal) + parseFloat(cartStore.$state.wntrCart.subtotal_vat)).toFixed(2)
          // return (parseFloat(cartStore.$state.wntrCart.subtotal)).toFixed(2)
          return cartStore.$state.wntrCart.subtotal
        }
        return "0.00"
      }),
      cartItems: computed(() => {
        return cartStore.$state?.wntrCart?.items;
      }),
      wishlistItemsCount: computed(() => {
        return wishlistStore.$state?.wntrWishlist?.length ? wishlistStore.$state?.wntrWishlist.length : 0;
      }),
      accountIcon,
      getCatLink,
      handleAccountClick,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleLoginModal,
      hasCurrencyToSelect,
      hasStoresToSelect,
      wntrNav,
      isUserLoggedIn,
      refreshToken,
      getUserCart,
      wntrCart,
      setQty,
      addToCart,
      createGuestCart,
      getWishlistAction
    };
  },
  // computed: {
  //   loggedIn() {
  //       return this.token != '' ? true : false;
  //     },
  // },
  async mounted() {

    this.getSettings();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    
    this.initialOrDetectedUserLogin()
    window.addEventListener('wntrUserLoggedIn', (event) => {
        this.initialOrDetectedUserLogin()
    })
    
    this.getWishlistAction(this.token);
    this.getCart();
  },
  created() {
      this.image_prefix = this.$config.baseImageUrl;
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    async cartItems(v) {
      if (await this.isUserLoggedIn() === true) {
        this.loggedIn = true;
      }
    }
  },
  methods: {
    async getSettings() {
      const settings = await this.loadSettings({ identifier: '' });
      localStorage.setItem('settings', JSON.stringify(settings));
    },
    async initialOrDetectedUserLogin() {
        if (localStorage.getItem('wntrUserSet')) {
            this.token = JSON.parse(localStorage.getItem('wntrUserSet')).token

            if (await this.isUserLoggedIn() == true) {
                // await this.refreshToken({
                //     token: this.token
                // });
                this.refreshTokenRunner();
                localStorage.removeItem('gc');
            }
        }
    },
    refreshTokenRunner() {
      this.refreshTokenAction()
      clearInterval(this.refreshTokenInterval)
      this.refreshTokenInterval = setInterval(async () => {
        this.refreshTokenAction()
      }, this.tokenLifetime)
    },
    async refreshTokenAction() {
      // console.log(`refreshToken: ${this.token}`)
      const res = await this.refreshToken({
          token: this.token
      });

      if (!res || res.error || res.err) {
          localStorage.removeItem('wntrUserSet')
          clearInterval(this.refreshTokenInterval)
          this.loggedIn = false
          localStorage.setItem('login_timeout', 'true');
          localStorage.removeItem('wntrWishlist');
          localStorage.removeItem('addressID');
          localStorage.removeItem('order_id');
          localStorage.removeItem('cc_items');
          localStorage.removeItem('cc');
          this.toggleLoginModal()
          return;
      }

      if (res && res.token) {
          this.token = res.token
          var wntrUserSet = JSON.parse(localStorage.getItem('wntrUserSet'))
          wntrUserSet.token = res.token
          localStorage.setItem('wntrUserSet', JSON.stringify(wntrUserSet))
      }
    },
    async userLogged() {
      return await this.isUserLoggedIn();
    },
    async getCart() {
      if (await this.userLogged()) {
        this.wntrCart = await this.getUserCart({ token: this.token });
      } else {
        if (
          !localStorage.getItem('guest_session') 
          || localStorage.getItem('guest_session') == 'undefined'
        ) {
          window.localStorage.setItem('guest_session', await this.createGuestCartAction());
        }
        this.wntrCart = await this.getUserCart({ token: null, customQuery: { guest_session:  window.localStorage.guest_session } });
      }
      const store = useWntrCartStore();

      store.$patch((state) => {
        state.wntrCart = this.wntrCart;
      });
    },
    async setItemQty(id, qty) {

      if (qty != 0) {

        await this.setQty({
          token: this.token,
          customQuery: {  
            id: id,
            qty: qty
          }
        });

        this.getCart();
      }
    },
    async createGuestCartAction() {
    
      const guestCartID = await this.createGuestCart();
      return guestCartID.guest_session;
    },
    setCurrentSubMenu: function (record) {
      if (record && record.children && Array.isArray(record.children)) {
        this.currentSubmenu = record.children[0].id;
      }
    },
    handleResize() {
      this.breakpointClass = window.matchMedia("(max-width: 991px)").matches
        ? "mobile"
        : "desktop";
    },
    toggleMobileMenu() {
      this.showMobileMenu = this.showMobileMenu ? false : true;
    },
    focusOnSearchInput() {
      // @ts-ignore
      this.$nextTick(() => this.$refs.search.focus());
    },
    // handleAccountClickAndMenu() {
    //   console.log('handleAccountClickAndMenu')
    //   // handleAccountClick
    //   // this.showSubmenu = null;
    //   // this.showMobileMenu = false;
    // }
  },
  data() {
    return {
      token: "",
      refreshTokenInterval: null,
      tokenLifetime: 300000,
      loggedIn: false,
      image_prefix: '',
      breakpointClass: null,
      showMobileMenu: false,
      showSubmenu: null,
      currentSubmenu: null,
      basketId: 1,
      basketQty: 1,
      run: true,
      basket: [
        {
          id: "123",
          quantity: 1,
        },
      ],
      basketProductMockup: {
        name: "Highline Kitchen Base Unit - Single",
        price: 71.86,
        img: "https://picsum.photos/400/400?test=nav-kitchens-unit-123",
        url: { name: "kitchens-units", params: { id: 123 } },
      },
    }
  },
  head() {
      return {
          bodyAttrs: {
              class: this.showMobileMenu ? 'stop-scrolling' : ''
          }
      }
  },
  props: {},
});
