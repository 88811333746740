






















import {
    defineComponent,
    // ref,
    // useContext,
    onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'CheckoutHeader',
    components: {
    },
    setup() {
      
      // $nuxt.setLayout('checkout');

      onMounted(async () => {
          
      });

      return {
          
      };
    },
});
