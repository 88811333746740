import { ref, readonly, useContext, useRouter } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import type { 
  WntrOrders,
  WntrQuote,
  WntrUser,
  WntrUserSet,
} from '~/modules/GraphQL/types';
import { Logger } from '~/helpers/logger';
import type { ComposableFunctionArgs } from '../types';
import type { WntrUseUserInterface, WntrUseUserErrors } from './wntrUseUser';
import { CustomQuery } from '@vue-storefront/magento-types';
import { createGuestUserCommand } from './commands/createGuestUserCommand';
import { createUserCommand } from './commands/createUserCommand';
import { createUserAddressCommand } from './commands/createUserAddressCommand';
import { userLoginCommand } from './commands/userLoginCommand';
import { updateUserAddressCommand } from './commands/updateUserAddressCommand';
import { deleteUserAddressCommand } from './commands/deleteUserAddressCommand';
import { getUserDetailsCommand } from './commands/getUserDetailsCommand';
import { getOrderCommand } from './commands/getOrderCommand';
import { getOrdersCommand } from './commands/getOrdersCommand';
import { getQuoteCommand } from './commands/getQuoteCommand';
import { getQuotesCommand } from './commands/getQuotesCommand';
import { updateUserCommand } from './commands/updateUserCommand';
import { requestUserPasswordResetCommand } from './commands/requestResetUserPasswordCommand';
import { resetUserPasswordCommand } from './commands/resetUserPasswordCommand';
import { activateUserCommand } from './commands/activateUserCommand';
import { registerPasswordCommand } from './commands/registerPasswordCommand';
import { refreshTokenCommand } from './commands/refreshTokenCommand';


/**
 * Allows loading CMS Pages or Blocks from Magento API.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function wntrUseUser(): WntrUseUserInterface {
  const loading: Ref<boolean> = ref(false);
  const error: Ref<WntrUseUserErrors> = ref({
    wntr_activateUser: null,
    wntr_createGuestUser: null,
    wntr_createUser: null,
    wntr_createUserAddress: null,
    wntr_updateUserAddress: null,
    wntr_deleteUserAddress: null,
    wntr_getUserDetails: null,
    wntr_userLogin: null,
    wntr_updateUser: null,
    wntr_order: null,
    wntr_orders: null,
    wntr_quotes: null,
    wntr_refreshTokenCommand: null,
    wntr_requestUserPasswordReset: null,
    wntr_resetUserPassword: null,
    wntr_registerPassword: null,
  });
  const { app } = useContext();
  const context = app.$vsf;

  async function activateUser(params: ComposableFunctionArgs<{ 
    query: string, 
  }>): Promise<string> {
    Logger.debug('useUser/activateUser');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_activateUser = null;
      result = await activateUserCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_activateUser = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function createGuestUser(params: ComposableFunctionArgs<{ 
    email: string, 
  }>): Promise<string> {
    Logger.debug('useUser/createGuestUser');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_createGuestUser = null;
      result = await createGuestUserCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_createGuestUser = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getUserDetails(params: ComposableFunctionArgs<{ 
    token: string, 
  }>): Promise<WntrUser> {
    Logger.debug('useUser/getUserDetails');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getUserDetails = null;
      result = await getUserDetailsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getUserDetails = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function updateUser(params: ComposableFunctionArgs<{ 
    token: string, 
    query?: CustomQuery 
  }>): Promise<WntrUser> {
    Logger.debug('useUser/updateUser');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_updateUser = null;
      result = await updateUserCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_updateUser = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function createUser(params: ComposableFunctionArgs<{ 
    query?: CustomQuery 
  }>): Promise<string> {
    Logger.debug('useUser/createUser');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_createUser = null;
      result = await createUserCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_createUser = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function createUserAddress(params: ComposableFunctionArgs<{ 
    token: string, 
    query?: CustomQuery, 
  }>): Promise<string> {
    Logger.debug('useUser/createUserAddress');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_createUserAddress = null;
      result = await createUserAddressCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_createUserAddress = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function updateUserAddress(params: ComposableFunctionArgs<{ 
    token: string, 
    query?: CustomQuery, 
  }>): Promise<string> {
    Logger.debug('useUser/updateUserAddress');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_updateUserAddress = null;
      result = await updateUserAddressCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_updateUserAddress = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function deleteUserAddress(params: ComposableFunctionArgs<{ 
    token: string 
    query?: CustomQuery 
  }>): Promise<string> {
    Logger.debug('useUser/deleteUserAddress');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_deleteUserAddress = null;
      result = await deleteUserAddressCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_deleteUserAddress = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getOrder(params: ComposableFunctionArgs<{ 
    token: string, 
    query?: CustomQuery 
  }>): Promise<WntrOrders> {
    Logger.debug('useUser/getOrder');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_order = null;
      result = await getOrderCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_order = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getOrders(params: ComposableFunctionArgs<{ 
    token: string, 
  }>): Promise<WntrOrders[]> {
    Logger.debug('useUser/getOrders');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_orders = null;
      result = await getOrdersCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_orders = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getQuote(params: ComposableFunctionArgs<{ 
    token: string, 
    query?: CustomQuery 
  }>): Promise<WntrOrders> {
    Logger.debug('useUser/getOrder');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_order = null;
      result = await getQuoteCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_order = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getQuotes(params: ComposableFunctionArgs<{ 
    token: string, 
  }>): Promise<WntrQuote[]> {
    Logger.debug('UseCart/getQuotes');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_quotes = null;
      result = await getQuotesCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_quotes = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function userLogin(params: ComposableFunctionArgs<{ 
    query?: CustomQuery 
  }>): Promise<WntrUserSet> {
    Logger.debug('useUser/userLogin');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_userLogin = null;
      result = await userLoginCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_userLogin = err;
    } finally {
      loading.value = false;
    }

    const userSet = {
      token: result ? result.token : '',
      user: result ? result.user : null,
      isLoggedIn: result['error'] ? false : true,
    }

    if (userSet.isLoggedIn) {
      window.localStorage.removeItem('order_id');
      window.localStorage.removeItem('cc_items');
      window.localStorage.removeItem('cc');
      window.localStorage.setItem('wntrUserSet', JSON.stringify(userSet));
      window.dispatchEvent(new CustomEvent('wntrUserLoggedIn'));
    }
    return result;
  }

  async function refreshToken(params: ComposableFunctionArgs<{ 
    token: string 
  }>): Promise<any> {
    Logger.debug('useUser/refreshToken');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_refreshToken = null;
      result = await refreshTokenCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_refreshToken = err;
    } finally {
      loading.value = false;
    }

    const userSet = localStorage.getItem('wntrUserSet') ? JSON.parse(localStorage.getItem('wntrUserSet')) : null;

    if (userSet && result?.token) {
      userSet['token'] = result?.token
      localStorage.setItem('wntrUserSet', JSON.stringify(userSet));
      return result;
    } else {

      if (result?.error) {
        return result;
      } else {
        return null;
      }
    }

  }

  async function userLogout() {
    localStorage.removeItem('wntrUserSet');
    localStorage.removeItem('wntrWishlist');
    localStorage.removeItem('addressID');
    localStorage.removeItem('order_id');
    localStorage.removeItem('cc_items');
    localStorage.removeItem('cc');
    location.href = '/';
  }
  
  async function isUserLoggedIn() {
    const lsItemExists = localStorage.getItem('wntrUserSet') ? true : false;
    if (lsItemExists) {
      const user = await getUserDetails({ token:  JSON.parse(window.localStorage.getItem('wntrUserSet')).token })
      if ((user && user.error) || !user) {
        localStorage.removeItem('wntrUserSet');
        localStorage.removeItem('wntrWishlist');
        localStorage.removeItem('addressID');
        localStorage.removeItem('order_id');
        return false;
      }
      if (user.email) return true;
      // userLogout();
      return false;
    }
    return false;
  }
  
  async function registerPassword(params: ComposableFunctionArgs<{ 
    query?: CustomQuery 
  }>): Promise<string> {
    Logger.debug('useUser/registerPassword');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_registerPassword = null;
      result = await registerPasswordCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_registerPassword = err;
    } finally {
      loading.value = false;
    }

    return result;
  }
  
  async function requestUserPasswordReset(params: ComposableFunctionArgs<{ 
    query?: CustomQuery 
  }>): Promise<string> {
    Logger.debug('useUser/requestUserPasswordReset');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_createUser = null;
      result = await requestUserPasswordResetCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_createUser = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function resetUserPassword(params: ComposableFunctionArgs<{ 
    query?: CustomQuery 
  }>): Promise<string> {
    Logger.debug('useUser/resetUserPassword');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_createUser = null;
      result = await resetUserPasswordCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_createUser = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    error: readonly(error),
    loading: readonly(loading),
    activateUser,
    createGuestUser,
    updateUserAddress,
    createUserAddress,
    deleteUserAddress,
    createUser,
    getUserDetails,
    getOrder,
    getOrders,
    getQuote,
    getQuotes,
    userLogin,
    userLogout,
    isUserLoggedIn,
    refreshToken,
    requestUserPasswordReset,
    resetUserPassword,
    updateUser,
    registerPassword
  };
}

export default wntrUseUser;
export * from './wntrUseUser';
