

















































































































































































import {
  SfImage,
} from '@storefront-ui/vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfImage,
  },
  setup() {
    return {
      addBasePath,
    };
  },
  data() {
    return {
      email: null,
      valid_email: false,
      consent: false,
      submittable_form: false
    };
  },
  methods: {
      checkFormValidation() {
          this.submittable_form = ( this.valid_email && this.consent ) ? true : false
      }
  },
  watch: {
      email (newValue, oldValue) {
          this.valid_email = (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) ? true : false
          this.checkFormValidation()
      },
      consent (newValue, oldValue) {
          this.checkFormValidation()
      }
  }
});
