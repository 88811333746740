import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const resetUserPasswordCommand = {
  execute: async (context: VsfContext, params) => {
    Logger.debug('[Winter CMS]: Get Order', { params });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const data = await context.$magento.api.resetUserPassword(params?.customQuery);

    Logger.debug('[Result]:', { data });
    return data;
  },
};
