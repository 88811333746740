import { ref, readonly, useContext } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { ComposableFunctionArgs } from '../types';
import type { WntrUseCartInterface, WntrUseCartErrors } from './wntrUseCart';
import { 
  CustomQuery, Scalars, WntrShippingRate
} from '@vue-storefront/magento-types';
import type { 
  WntrDefaults,
  WntrCart,
  WntrGuestCartID,
  WntrWishlistItem
} from '~/modules/GraphQL/types';
import { getDefaultsCommand } from './commands/getDefaultsCommand';
import { setDefaultsCommand } from './commands/setDefaultsCommand';
import { clearDefaultsCommand } from './commands/clearDefaultsCommand';
import { getUserCartCommand } from './commands/getUserCartCommand';
import { addToCartCommand } from './commands/addToCartCommand';
import { setQtyCommand } from './commands/setQtyCommand';
import { deleteItemCommand } from './commands/deleteItemCommand';
import { emptyCartCommand } from './commands/emptyCartCommand';
import { applyCouponCommand } from './commands/applyCouponCommand';
import { saveCartCommand } from './commands/saveCartCommand';
import { checkSaveSessionCommand } from './commands/checkSaveSessionCommand';
import { loadSavedBasketCommand } from './commands/loadSavedBasketCommand';
import { saveQuoteCommand } from './commands/saveQuoteCommand';
import { createGuestCartCommand } from './commands/createGuestCartCommand';
import { loadQuoteCommand } from './commands/loadQuoteCommand';
import { getWishlistCommand } from './commands/getWishlistCommand';
import { addToWishlistCommand } from './commands/addToWishlistCommand';
import { deleteFromWishlistCommand } from './commands/deleteFromWishlistCommand';
import { deleteWishlistCommand } from './commands/deleteWishlistCommand';
import { getOrderIDCommand } from './commands/getOrderIDCommand';
import { getPaymentMethodsCommand } from './commands/getPaymentMethodsCommand';
import { getPaymentStatusCommand } from './commands/getPaymentStatusCommand';
import { getPaymentURLCommand } from './commands/getPaymentURLCommand';
import { getShippingRatesCommand } from './commands/getShippingRatesCommand';
import { placeBACSOrderCommand } from './commands/placeBACSOrderCommand';
import { checkGuestEmailCommand } from './commands/checkGuestEmailCommand';


/**
 * Allows loading CMS Pages or Blocks from Magento API.
 *
 * See the {@link UseCartInterface} for a list of methods and values available in this composable.
 */
export function wntrUseCart(): WntrUseCartInterface {
  const loading: Ref<boolean> = ref(false);
  const error: Ref<any> = ref({
    wntr_defaults: null,
    wntr_setDefaults: null,
    wntr_clearDefaults: null,
    wntr_userCart: null,
    wntr_addToCart: null,
    wntr_setQty: null,
    wntr_deleteItem: null,
    wntr_emptyCart: null,
    wntr_applyCoupon: null,
    wntr_saveCart: null,
    wntr_checkSaveSession: null,
    wntr_loadSavedBasket: null,
    wntr_saveQuote: null,
    wntr_createGuestCart: null,
    wntr_loadQuote: null,
    wntr_getWishlistg: null,
    wntr_addToWishlistg: null,
    wntr_deleteFromWishlistg: null,
    wntr_deleteWishlistg: null,
    wntr_getCheckout: null,
    wntr_getOrderID: null,
    wntr_getPaymentMethods: null,
    wntr_getPaymentStatus: null,
    wntr_getPaymentURL: null,
    wntr_getShippingRates: null,
    wntr_placeBACSOrder: null,
    wntr_checkGuestEmail: null
  });
  const { app } = useContext();
  const context = app.$vsf;

  async function getDefaults(params: ComposableFunctionArgs<{ 
    token: string, 
  }>): Promise<WntrDefaults> {
    Logger.debug('UseCart/getDefaults');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_defaults = null;
      result = await getDefaultsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_defaults = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function setDefaults(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery
  }>): Promise<string | null> {
    Logger.debug('UseCart/setDefaults');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_setDefaults = null;
      result = await setDefaultsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_setDefaults = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function clearDefaults(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery
  }>): Promise<string | null> {
    Logger.debug('UseCart/clearDefaults');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_clearDefaults = null;
      result = await clearDefaultsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_clearDefaults = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getUserCart(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<WntrCart> {
    Logger.debug('UseCart/getUserCart');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_userCart = null;
      result = await getUserCartCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_userCart = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function addToCart(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: any, 
  }>): Promise<string | null> {
    Logger.debug('UseCart/addToCart');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_addToCart = null;
      result = await addToCartCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_addToCart = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function setQty(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<WntrCart> {
    Logger.debug('UseCart/setQty');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_setQty = null;
      result = await setQtyCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_setQty = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function deleteItem(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<WntrCart> {
    Logger.debug('UseCart/deleteItem');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_deleteItem = null;
      result = await deleteItemCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_deleteItem = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function emptyCart(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<WntrCart> {
    Logger.debug('UseCart/emptyCart');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_emptyCart = null;
      result = await emptyCartCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_emptyCart = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function applyCoupon(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<WntrCart> {
    Logger.debug('UseCart/applyCoupon');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_applyCoupon = null;
      result = await applyCouponCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_applyCoupon = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function saveCart(params: ComposableFunctionArgs<{ 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('UseCart/saveCart');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_saveCart = null;
      result = await saveCartCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_saveCart = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function checkSaveSession(params: ComposableFunctionArgs<{ 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('UseCart/checkSaveSession');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_checkSaveSession = null;
      result = await checkSaveSessionCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_checkSaveSession = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadSavedBasket(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('UseCart/loadSavedBasket');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_loadSavedBasket = null;
      result = await loadSavedBasketCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_loadSavedBasket = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function saveQuote(params: ComposableFunctionArgs<{ 
    token: string, 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('UseCart/saveQuote');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_saveQuote = null;
      result = await saveQuoteCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_saveQuote = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadQuote(params: ComposableFunctionArgs<{ 
    token: string, 
    query: CustomQuery, 
  }>): Promise<WntrCart> {
    Logger.debug('Quotes/loadQuote');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_loadQuote = null;
      result = await loadQuoteCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_loadQuote = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function createGuestCart(): Promise<WntrGuestCartID> {
    Logger.debug('UseCart/createGuestCart');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_createGuestCart = null;
      result = await createGuestCartCommand.execute(context);
    } catch (err) {
      error.value.wntr_createGuestCart = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getWishlist(params: ComposableFunctionArgs<{ 
    token: string 
  }>): Promise<WntrWishlistItem[]> {
    Logger.debug('UseCart/getWishlist');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getWishlist = null;
      result = await getWishlistCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getWishlist = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function deleteWishlist(params: ComposableFunctionArgs<{ 
    token: string 
  }>): Promise<string> {
    Logger.debug('UseCart/deleteWishlist');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getWishlist = null;
      result = await deleteWishlistCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getWishlist = err;
    } finally {
      loading.value = false;
    }

    return result;
  }
  
  async function addToWishlist(params: ComposableFunctionArgs<{ 
    token: string,
    query: CustomQuery, 
  }>): Promise<string> {
    Logger.debug('UseCart/addToWishlist');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getWishlist = null;
      result = await addToWishlistCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getWishlist = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function deleteFromWishlist(params: ComposableFunctionArgs<{ 
    token: string,
    query: CustomQuery, 
  }>): Promise<string> {
    Logger.debug('UseCart/deleteFromWishlist');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getWishlist = null;
      result = await deleteFromWishlistCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getWishlist = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getOrderID(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('Cart/getOrderID');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getOrderID = null;
      result = await getOrderIDCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getOrderID = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function checkGuestEmail(params: ComposableFunctionArgs<{  
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('Cart/checkGuestEmail');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_checkGuestEmail = null;
      result = await checkGuestEmailCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_checkGuestEmail = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getPaymentMethods(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('Cart/getPaymentMethods');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getPaymentMethods = null;
      result = await getPaymentMethodsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getPaymentMethods = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getPaymentStatus(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('Cart/getPaymentStatus');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getPaymentStatus = null;
      result = await getPaymentStatusCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getPaymentStatus = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getPaymentURL(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('Cart/getPaymentURL');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getPaymentURL = null;
      result = await getPaymentURLCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getPaymentURL = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function placeBACSOrder(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<any> {
    Logger.debug('Cart/placeBACSOrder');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_placeBACSOrder = null;
      result = await placeBACSOrderCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_placeBACSOrder = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getShippingRates(params: ComposableFunctionArgs<{ 
    token?: string, 
    query?: CustomQuery, 
  }>): Promise<WntrShippingRate[]> {
    Logger.debug('Cart/getShippingRates');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_getShippingRates = null;
      result = await getShippingRatesCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_getShippingRatest = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    error: readonly(error),
    loading: readonly(loading),
    getDefaults,
    setDefaults,
    clearDefaults,
    getUserCart,
    addToCart,
    setQty,
    deleteItem,
    emptyCart,
    applyCoupon,
    saveCart,
    checkSaveSession,
    loadSavedBasket,
    saveQuote,
    loadQuote,
    createGuestCart,
    getWishlist,
    deleteWishlist,
    addToWishlist,
    deleteFromWishlist,
    getOrderID,
    getPaymentMethods,
    getPaymentStatus,
    getPaymentURL,
    getShippingRates,
    placeBACSOrder,
    checkGuestEmail
  };
}

export default wntrUseCart;
export * from './wntrUseCart';
