
import { Logger } from '~/helpers/logger'
import { VsfContext } from '~/composables/context'

export const getProductDetailsCommand = {
  execute: async (context: VsfContext, params) => {
    Logger.debug('[Winter CMS]: Get Product Details')
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const data = await context.$magento.api.getProductDetails(params)
    Logger.debug('[Result]:', { data })
    return data
  }
}