import { defineStore } from 'pinia';
import { WntrCart } from '~/modules/GraphQL/types';

export interface WntrCartStore {
  wntrCart: WntrCart | null;
}

export const useWntrCartStore = defineStore('wntrCart', {
  state: (): WntrCartStore => ({
    wntrCart: null,
  }),
});