




    import { defineComponent } from '@nuxtjs/composition-api';
	export default defineComponent({
		name: 'BlankLayout',
		components: {
		},
		setup() {
			return {
				
			};
		},
		methods: {
			
		},
		mounted: function mounted () {
			
		},
		data() {
			return {
			
            }
		}
	});
