import { WntrWishlistItem } from "~/modules/GraphQL/types";
import { defineStore } from 'pinia';

export interface WntrWishlistStore {
  wntrWishlist: WntrWishlistItem[] | null;
}

export const useWntrWishlistStore = defineStore('wntrWishlist', {
  state: (): WntrWishlistStore => ({
    wntrWishlist: null,
  }),
});