import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const addToWishlistCommand = {
  execute: async (context: VsfContext, params) => {
    Logger.debug('[Winter CMS]: Add to Wishlist', { params });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const data = await context.$magento.api.addToWishlist(params.token, params.customQuery);

    Logger.debug('[Result]:', { data });
    return data;
  },
};