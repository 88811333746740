
import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const getDefaultsCommand = {
  execute: async (context: VsfContext, params) => {
    Logger.debug('[Winter CMS]: Get Defaults', { params });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const data = await context.$magento.api.getDefaults(params.token);

    Logger.debug('[Result]:', { data });
    return data;
  },
};