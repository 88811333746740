import { ref, readonly, useContext } from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import type { 
  CmsPage, 
  CmsBlock, 
  RangeSliderCategory, 
  DynamicPage,
  DynamicComponent,
  MainNavigationItem, 
  BlogCategory,
  // BlogArticle,
  ConsultationForm,
  BlogPost,
  CustomerStories,
  CustomerStory,
  DeleteItem,
  Guides,
  Guide,
  Settings,
  WntrAttribute,
  WntrAttributeOption,
  WntrProduct,
  WntrProductDetails,
  WntrProductPrice,
  WntrProductURL,
  WntrDoors,
  WntrNavCategory,
  WntrTrustPilot,
  CategoryURL,
  Finishes,
  WntrCategoryProducts,
  Sitemap
} from '~/modules/GraphQL/types';
import { Logger } from '~/helpers/logger';
import { loadGetSettingsCommand } from './commands/loadGetSettingsCommand';
import { getAttributeCommand } from './commands/getAttributeCommand';
import { getAttributesCommand } from './commands/getAttributesCommand';
import { getAttributeOptionsCommand } from './commands/getAttributeOptionsCommand';
import { getNavCommand } from './commands/getNavCommand';
import { getProductCommand } from './commands/getProductCommand';
import { getProductDetailsCommand } from './commands/getProductDetailsCommand';
import { getProductPricesCommand } from './commands/getProductPricesCommand';
import { getProductURLCommand } from './commands/getProductURLCommand';
import { getDoorsCommand } from './commands/getDoorsCommand';
import { getProductsCommand } from './commands/getProductsCommand';
import { getTrustPilotDataCommand } from './commands/getTrustPilotDataCommand';
import { getCategoryURLCommand } from './commands/getCategoryURLCommand';
import { loadContentCommand } from './commands/loadContentCommand';
import { loadRangeSliderCommand } from './commands/loadRangeSliderCommand';
import { loadDynamicPageCommand } from './commands/loadDynamicPageCommand';
import { loadDynamicComponentsCommand } from './commands/loadDynamicComponentsCommand';
import { loadMainNavigationCommand } from './commands/loadMainNavigationCommand';
import { loadDeleteItemCommand } from './commands/loadDeleteItemCommand';
import { loadBlogPostsCommand } from './commands/loadBlogPostsCommand';
// import { loadBlogArticleCommand } from './commands/loadBlogArticleCommand';
import { submitContactCommand } from './commands/SubmitContactCommand';
import { loadBlogPostCommand } from './commands/loadBlogPostCommand';
import { loadBlocksCommand } from './commands/loadBlocksCommand';
import { loadCustomerStoriesCommand } from './commands/loadCustomerStoriesCommand';
import { loadCustomerStoryCommand } from './commands/loadCustomerStoryCommand';
import { loadGuidesCommand } from './commands/loadGuidesCommand';
import { loadGuideCommand } from './commands/loadGuideCommand';
import { getFinishesCommand } from './commands/getFinishesCommand';
import { getWntrCategoryProductsCommand } from './commands/getWntrCategoryProductsCommand';
import type { ComposableFunctionArgs } from '../types';
import type { UseContentInterface, UseContentErrors } from './useContent';
import { CustomQuery } from '@vue-storefront/magento-types';
import { loadSitemapCommand } from './commands/loadSitemapCommand';

/**
 * Allows loading CMS Pages or Blocks from Magento API.
 *
 * See the {@link UseContentInterface} for a list of methods and values available in this composable.
 */
export function useContent(): UseContentInterface {
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseContentErrors> = ref({
    settings: null,
    wntr_attribute: null,
    wntr_attributes: null,
    wntr_attributeOptions: null,
    wntr_navCategory: null,
    wntr_product: null,
    wntr_productDetails: null,
    wntr_productPrices: null,
    wntr_productURL: null,
    wntr_doors: null,
    wntr_products: null,
    wntr_trustPilot: null,
    wntr_categoryProducts: null,
    CategoryURL: null,
    page: null,
    rangeSlider: null,
    dynamicPage: null,
    dynamicComponents: null,
    mainNavigation: null,
    blog_categories: null,
    // blog_article: null,
    submit_contact: null,
    blog_post: null,
    customer_stories: null,
    customer_story: null,
    blocks: null,
    finishes: null,
    deleteItem: null,
    cForm: null
  });
  const { app } = useContext();
  const context = app.$vsf;

  async function loadSettings(params: ComposableFunctionArgs<{ identifier: string }>): Promise<Settings> {
    Logger.debug('useContent/loadSettings');
    loading.value = true;
    let result = null;

    try {
      error.value.settings = null;
      result = await loadGetSettingsCommand.execute(context, params);
    } catch (err) {
      error.value.settings = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getAttribute(params: ComposableFunctionArgs<{ id: string }>): Promise<WntrAttribute> {
    Logger.debug('useContent/getAttribute');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_attribute = null;
      result = await getAttributeCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_attribute = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getAttributes(): Promise<WntrAttribute[]> {
    Logger.debug('useContent/getAttribute');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_attributes = null;
      result = await getAttributesCommand.execute(context);
    } catch (err) {
      error.value.wntr_attributes = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getAttributeOptions(params: ComposableFunctionArgs<{ 
    product_id: string,
    query?: CustomQuery
  }>): Promise<WntrAttributeOption[]> {
    Logger.debug('useContent/getAttributeOptions');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_attributeOptions = null;
      result = await getAttributeOptionsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_attributeOptions = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getNav(): Promise<WntrNavCategory[]> {
    Logger.debug('useContent/getAttribute');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_navCategory = null;
      result = await getNavCommand.execute(context);
    } catch (err) {
      error.value.wntr_navCategory = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getProduct(params: ComposableFunctionArgs<{ 
    product_id: string,
    query?: CustomQuery
  }>): Promise<WntrProduct> {
    Logger.debug('useContent/getProduct');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_product = null;
      result = await getProductCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_product = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getProductDetails(params: ComposableFunctionArgs<{ 
    query?: CustomQuery
  }>): Promise<WntrProductDetails> {
    Logger.debug('useContent/getProductDetails');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_productDetails = null;
      result = await getProductDetailsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_productDetails = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getProductPrices(params: ComposableFunctionArgs<{ query?: CustomQuery }>): Promise<WntrProductPrice> {
    Logger.debug('useContent/products/price');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_productPrices = null;
      result = await getProductPricesCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_productPrices = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getWntrCategoryProducts(params: ComposableFunctionArgs<{ identifier: string, params: CustomQuery }>): Promise<WntrCategoryProducts> {
    Logger.debug('useContent/category-products');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_categoryProducts = null;
      result = await getWntrCategoryProductsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_categoryProducts = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getProductURL(params: ComposableFunctionArgs<{ product_id: string }>): Promise<WntrProductURL> {
    Logger.debug('useContent/getProductURL');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_productURL = null;
      result = await getProductURLCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_productURL = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getDoors(params?: ComposableFunctionArgs<{ query?: CustomQuery }>): Promise<WntrDoors[]> {
    Logger.debug('useContent/getDoors');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_doors = null;
      result = await getDoorsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_doors = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getProducts(params: ComposableFunctionArgs<{ query?: CustomQuery }>): Promise<WntrProduct[]> {
    Logger.debug('useContent/getProducts');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_products = null;
      result = await getProductsCommand.execute(context, params);
    } catch (err) {
      error.value.wntr_products = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getTrustPilotData(): Promise<WntrTrustPilot> {
    Logger.debug('useContent/getTrustPilotData');
    loading.value = true;
    let result = null;

    try {
      error.value.wntr_trustPilot = null;
      result = await getTrustPilotDataCommand.execute(context);
    } catch (err) {
      error.value.wntr_trustPilot = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getCategoryURL(params: ComposableFunctionArgs<{ identifier: string }>): Promise<CategoryURL> {
    Logger.debug('useContent/getCategoryURL');
    loading.value = true;
    let result = null;

    try {
      error.value.CategoryURL = null;
      result = await getCategoryURLCommand.execute(context, params);
    } catch (err) {
      error.value.CategoryURL = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function getFinishes(params?: ComposableFunctionArgs<{ ids?: string }>): Promise<Finishes[]> {
    Logger.debug('useContent/getFinishes');
    loading.value = true;
    let result = null;

    try {
      error.value.finishes = null;
      result = await getFinishesCommand.execute(context, params);
    } catch (err) {
      error.value.finishes = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadPage(params: ComposableFunctionArgs<{ identifier: string }>): Promise<CmsPage> {
    Logger.debug('useContent/loadPage');
    loading.value = true;
    let result = null;

    try {
      error.value.page = null;
      result = await loadContentCommand.execute(context, params);
    } catch (err) {
      error.value.page = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadRangeSlider(params: ComposableFunctionArgs<{ identifier: string }>): Promise<RangeSliderCategory[]> {
    Logger.debug('useContent/loadRangeSlider');
    loading.value = true;
    let result = null;

    try {
      error.value.rangeSlider = null;
      result = await loadRangeSliderCommand.execute(context, params);
    } catch (err) {
      error.value.rangeSlider = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadDynamicPage(params: ComposableFunctionArgs<{ identifier: string }>): Promise<DynamicPage> {
    Logger.debug('useContent/loadDynamicPage');
    loading.value = true;
    let result = null;

    try {
      error.value.dynamicPage = null;
      result = await loadDynamicPageCommand.execute(context, params);
    } catch (err) {
      error.value.dynamicPage = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadDynamicComponents(params: ComposableFunctionArgs<{ identifier: string }>): Promise<DynamicComponent[]> {
    Logger.debug('useContent/loadDynamicComponents');
    loading.value = true;
    let result = null;

    try {
      error.value.dynamicComponents = null;
      result = await loadDynamicComponentsCommand.execute(context, params);
    } catch (err) {
      error.value.dynamicComponents = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadMainNavigation(params: ComposableFunctionArgs<{ identifier: string }>): Promise<MainNavigationItem[]> {
    Logger.debug('useContent/loadMainNavigation');
    loading.value = true;
    let result = null;

    try {
      error.value.mainNavigation = null;
      result = await loadMainNavigationCommand.execute(context, params);
    } catch (err) {
      error.value.mainNavigation = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadDeleteItem(params: ComposableFunctionArgs<{ params: object }>): Promise<DeleteItem> {
    Logger.debug('useContent/loadDeleteItem');
    loading.value = true;
    let result = null;

    try {
      error.value.deleteItem = null;
      result = await loadDeleteItemCommand.execute(context, params);
    } catch (err) {
      error.value.deleteItem = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadBlogPosts(params: ComposableFunctionArgs<{ identifier: string }>): Promise<BlogCategory[]> {
    Logger.debug('useContent/loadBlogPosts');
    loading.value = true;
    let result = null;

    try {
      error.value.blog_categories = null;
      result = await loadBlogPostsCommand.execute(context, params);
    } catch (err) {
      error.value.blog_categories = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function submitContact(params: ComposableFunctionArgs<{ formObject: any }>): Promise<string> {
    Logger.debug('useContent/submitContact');
    loading.value = true;
    let result = null;

    try {
      error.value.submit_contact = null;
      result = await submitContactCommand.execute(context, params);
    } catch (err) {
      error.value.submit_contact = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadBlogPost(params: ComposableFunctionArgs<{ identifier: string }>): Promise<BlogPost> {
    Logger.debug('useContent/loadBlogPost');
    loading.value = true;
    let result = null;

    try {
      error.value.blog_post = null;
      result = await loadBlogPostCommand.execute(context, params);
    } catch (err) {
      error.value.blog_post = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadBlocks(params: ComposableFunctionArgs<{ identifiers: string[] }>): Promise<CmsBlock[]> {
    Logger.debug('useContent/loadBlocks');
    loading.value = true;
    let result = [];

    try {
      error.value.blocks = null;
      result = await loadBlocksCommand.execute(context, params);
    } catch (err) {
      error.value.blocks = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadCustomerStories(): Promise<CustomerStories> {
  // async function loadCustomerStories(params: ComposableFunctionArgs<{ identifier: string }>): Promise<CustomerStories> {
    Logger.debug('useContent/loadCustomerStories');
    loading.value = true;
    let result = null;

    try {
      error.value.customer_stories = null;
      result = await loadCustomerStoriesCommand.execute(context);
    } catch (err) {
      error.value.customer_stories = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadCustomerStory(params: ComposableFunctionArgs<{ identifier: string }>): Promise<CustomerStory> {
    Logger.debug('useContent/loadCustomerStory');
    loading.value = true;
    let result = null;

    try {
      error.value.customer_story = null;
      result = await loadCustomerStoryCommand.execute(context, params);
    } catch (err) {
      error.value.customer_story = err;
    } finally {
      loading.value = false;
    }

    return result;
  }


  async function loadGuides(): Promise<Guides> {
  // async function loadGuides(params: ComposableFunctionArgs<{ identifier: string }>): Promise<Guides> {
    Logger.debug('useContent/loadGuides');
    loading.value = true;
    let result = null;

    try {
      error.value.guides = null;
      result = await loadGuidesCommand.execute(context);
    } catch (err) {
      error.value.guides = err;
    } finally {
      loading.value = false;
    }

    return result;
  }
  
  async function loadGuide(params: ComposableFunctionArgs<{ identifier: string }>): Promise<Guide> {
    Logger.debug('useContent/loadGuide');
    loading.value = true;
    let result = null;

    try {
      error.value.guide = null;
      result = await loadGuideCommand.execute(context, params);
    } catch (err) {
      error.value.guide = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  async function loadSitemap(): Promise<Sitemap> {
    Logger.debug('useContent/loadSitemap');
    loading.value = true;
    let result = null;

    try {
      error.value.sitemap = null;
      result = await loadSitemapCommand.execute(context);
    } catch (err) {
      error.value.sitemap = err;
    } finally {
      loading.value = false;
    }

    return result;
  }

  return {
    error: readonly(error),
    loading: readonly(loading),
    loadPage,
    loadSettings,
    getAttribute,
    getAttributes,
    getNav,
    getAttributeOptions,
    getProduct,
    getProductDetails,
    getProductPrices,
    getProductURL,
    getDoors,
    getProducts,
    getTrustPilotData,
    getCategoryURL,
    loadRangeSlider,
    loadDynamicPage,
    loadDynamicComponents,
    loadMainNavigation,
    submitContact,
    loadBlogPosts,
    loadBlogPost,
    loadBlocks,
    loadCustomerStories,
    loadCustomerStory,
    loadGuides,
    loadGuide,
    getFinishes,
    loadDeleteItem,
    getWntrCategoryProducts,
    loadSitemap
  };
}

export default useContent;
export * from './useContent';
