import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e503b35e = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _90e710f8 = () => interopDefault(import('../modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _4d358908 = () => interopDefault(import('../modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _27a262c6 = () => interopDefault(import('../modules/checkout/pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _ea43beb0 = () => interopDefault(import('../modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _7a19f1ba = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _ac50eeaa = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _334aaf70 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _51c00fda = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _bdb2f244 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _e4866bac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _39e18bf0 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _a0b36474 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _34025bdc = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _614cbb1e = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _27318dd6 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _51c4725e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _0a594199 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _56f7752b = () => interopDefault(import('../pages/NotFound.vue' /* webpackChunkName: "" */))
const _e94d0d08 = () => interopDefault(import('../pages/Error.vue' /* webpackChunkName: "" */))
const _32a2c781 = () => interopDefault(import('../pages/Wishlist.vue' /* webpackChunkName: "" */))
const _4310bd42 = () => interopDefault(import('../pages/Register.vue' /* webpackChunkName: "" */))
const _5aaa5fbe = () => interopDefault(import('../pages/Account.vue' /* webpackChunkName: "" */))
const _5696e6e4 = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "" */))
const _50af5a97 = () => interopDefault(import('../pages/OrderConfirmation.vue' /* webpackChunkName: "" */))
const _6c631610 = () => interopDefault(import('../pages/Regular.vue' /* webpackChunkName: "" */))
const _69ced2c4 = () => interopDefault(import('../pages/BlogPost.vue' /* webpackChunkName: "" */))
const _263d0644 = () => interopDefault(import('../pages/Blog.vue' /* webpackChunkName: "" */))
const _081d6f20 = () => interopDefault(import('../pages/Guide.vue' /* webpackChunkName: "" */))
const _f439329a = () => interopDefault(import('../pages/Guides.vue' /* webpackChunkName: "" */))
const _30b224c3 = () => interopDefault(import('../pages/Support.vue' /* webpackChunkName: "" */))
const _6c0140aa = () => interopDefault(import('../pages/CustomerStory.vue' /* webpackChunkName: "" */))
const _56d2b889 = () => interopDefault(import('../pages/CustomerStories.vue' /* webpackChunkName: "" */))
const _47bf8ac0 = () => interopDefault(import('../pages/PDP-Component.vue' /* webpackChunkName: "" */))
const _4e721e76 = () => interopDefault(import('../pages/PLP-Filters.vue' /* webpackChunkName: "" */))
const _145a2e71 = () => interopDefault(import('../pages/PLP-Categories.vue' /* webpackChunkName: "" */))
const _02566e4d = () => interopDefault(import('../pages/PLP-Gallery.vue' /* webpackChunkName: "" */))
const _7984664a = () => interopDefault(import('../pages/BedroomBuilder.vue' /* webpackChunkName: "" */))
const _2299dfd3 = () => interopDefault(import('../pages/Bedrooms.vue' /* webpackChunkName: "" */))
const _563a5f7d = () => interopDefault(import('../pages/BedroomRange.vue' /* webpackChunkName: "" */))
const _4dcbbd13 = () => interopDefault(import('../pages/KitchenBuilder.vue' /* webpackChunkName: "" */))
const _bd634d62 = () => interopDefault(import('../pages/NewPassword.vue' /* webpackChunkName: "" */))
const _698e5a0b = () => interopDefault(import('../pages/Kitchens.vue' /* webpackChunkName: "" */))
const _85120496 = () => interopDefault(import('../pages/KitchenRange.vue' /* webpackChunkName: "" */))
const _115548ca = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "" */))
const _2a437068 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _6feb273d = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _478a714b = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _109e6ea9 = () => interopDefault(import('../pages/Sitemap.vue' /* webpackChunkName: "pages/Sitemap" */))
const _34b16273 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _e503b35e,
    name: "checkout",
    children: [{
      path: "user-account",
      component: _90e710f8,
      name: "user-account"
    }, {
      path: "shipping",
      component: _4d358908,
      name: "shipping"
    }, {
      path: "billing",
      component: _27a262c6,
      name: "billing"
    }, {
      path: "payment",
      component: _ea43beb0,
      name: "payment"
    }, {
      path: "thank-you",
      component: _7a19f1ba,
      name: "thank-you"
    }]
  }, {
    path: "/cart",
    component: _ac50eeaa,
    name: "cart"
  }, {
    path: "/customer",
    component: _334aaf70,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _51c00fda,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _bdb2f244,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _e4866bac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _39e18bf0,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "my-newsletter",
      component: _a0b36474,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter"
    }, {
      path: "my-wishlist",
      component: _34025bdc,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist"
    }, {
      path: "order-history",
      component: _614cbb1e,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _27318dd6,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "my-reviews",
      component: _51c4725e,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews"
    }, {
      path: "/reset-password",
      component: _0a594199,
      alias: "/customer/account/createPassword",
      name: "reset-password"
    }]
  }, {
    path: "/404",
    component: _56f7752b,
    name: "NotFound"
  }, {
    path: "/error",
    component: _e94d0d08,
    name: "Error"
  }, {
    path: "/wishlist",
    component: _32a2c781,
    name: "wishlist"
  }, {
    path: "/register",
    component: _4310bd42,
    name: "register"
  }, {
    path: "/account",
    component: _5aaa5fbe,
    name: "account"
  }, {
    path: "/search",
    component: _5696e6e4,
    name: "search"
  }, {
    path: "/order-confirmed/:id",
    component: _50af5a97,
    name: "order-confirmed"
  }, {
    path: "/regular",
    component: _6c631610,
    name: "regular"
  }, {
    path: "/design-tips-trends/:id",
    component: _69ced2c4,
    name: "design-tips-trends-detail"
  }, {
    path: "/design-tips-trends",
    component: _263d0644,
    name: "design-tips-trends"
  }, {
    path: "/guides/:id",
    component: _081d6f20,
    name: "guides-detail"
  }, {
    path: "/guides",
    component: _f439329a,
    name: "guides"
  }, {
    path: "/support",
    component: _30b224c3,
    name: "support"
  }, {
    path: "/customer-stories/:id",
    component: _6c0140aa,
    name: "customer-stories-detail"
  }, {
    path: "/customer-stories",
    component: _56d2b889,
    name: "customer-stories"
  }, {
    path: "/p/components/handles/*",
    component: _47bf8ac0,
    name: "components-handles-pdp"
  }, {
    path: "/components/handles/:key/:value",
    component: _4e721e76,
    name: "components-handles-specific"
  }, {
    path: "/components/handles/:key",
    component: _56f7752b,
    name: "components-handles-specific"
  }, {
    path: "/components/handles",
    component: _4e721e76,
    name: "components-handles"
  }, {
    path: "/p/components/bins-storage/*",
    component: _47bf8ac0,
    name: "components-bins-storage-pdp"
  }, {
    path: "/components/bins-storage/:key/:value",
    component: _4e721e76,
    name: "components-bins-storage-specific"
  }, {
    path: "/components/bins-storage/:key",
    component: _56f7752b,
    name: "components-bins-storage-specific"
  }, {
    path: "/components/bins-storage",
    component: _4e721e76,
    name: "components-bins-storage"
  }, {
    path: "/p/components/accessories/*",
    component: _47bf8ac0,
    name: "components-accessories-pdp"
  }, {
    path: "/components/accessories/:category/:subcategory",
    component: _145a2e71,
    name: "components-accessories-specified-2"
  }, {
    path: "/components/accessories/:category",
    component: _145a2e71,
    name: "components-accessories-specified-1"
  }, {
    path: "/components/accessories",
    component: _145a2e71,
    name: "components-accessories"
  }, {
    path: "/p/components/door-range-accessories/*",
    component: _47bf8ac0,
    name: "components-door-range-accessories-pdp"
  }, {
    path: "/components/door-range-accessories/:category/:subcategory",
    component: _145a2e71,
    name: "components-door-range-accessories-specified-2"
  }, {
    path: "/components/door-range-accessories/:category",
    component: _145a2e71,
    name: "components-door-range-accessories-specified-1"
  }, {
    path: "/components/door-range-accessories",
    component: _145a2e71,
    name: "components-door-range-accessories"
  }, {
    path: "/p/components/doors/wardrobe-doors/:id",
    component: _47bf8ac0,
    name: "components-doors-wardrobe-doors-pdp"
  }, {
    path: "/components/doors/wardrobe-doors/:key/:value",
    component: _02566e4d,
    name: "components-doors-wardrobe-doors-specific"
  }, {
    path: "/components/doors/wardrobe-doors/:key",
    component: _56f7752b,
    name: "components-doors-wardrobe-doors-specific"
  }, {
    path: "/components/doors/wardrobe-doors",
    component: _02566e4d,
    name: "components-doors-wardrobe-doors"
  }, {
    path: "/p/components/doors/kitchen-doors/:id",
    component: _47bf8ac0,
    name: "components-doors-kitchen-doors-pdp"
  }, {
    path: "/components/doors/kitchen-doors/:key/:value",
    component: _02566e4d,
    name: "components-doors-kitchen-doors-specific"
  }, {
    path: "/components/doors/kitchen-doors/:key",
    component: _56f7752b,
    name: "components-doors-kitchen-doors-specific"
  }, {
    path: "/components/doors/kitchen-doors",
    component: _02566e4d,
    name: "components-doors-kitchen-doors"
  }, {
    path: "/p/components/units/plinths-and-panels/*",
    component: _47bf8ac0,
    name: "components-units-plinths-and-panels-pdp"
  }, {
    path: "/components/units/plinths-and-panels",
    component: _4e721e76,
    name: "components-units-plinths-and-panels"
  }, {
    path: "/p/components/units/bedroom-units/*",
    component: _47bf8ac0,
    name: "components-units-bedroom-units-pdp"
  }, {
    path: "/components/units/bedroom-units/:category/:subcategory",
    component: _145a2e71,
    name: "components-units-bedroom-units-specified-2"
  }, {
    path: "/components/units/bedroom-units/:category",
    component: _145a2e71,
    name: "components-units-bedroom-units-specified-1"
  }, {
    path: "/components/units/bedroom-units",
    component: _145a2e71,
    name: "components-units-bedroom-units"
  }, {
    path: "/p/components/units/kitchen-units/*",
    component: _47bf8ac0,
    name: "components-units-kitchen-units-pdp"
  }, {
    path: "/components/units/kitchen-units/:category/:subcategory",
    component: _145a2e71,
    name: "components-units-kitchen-units-specified-2"
  }, {
    path: "/components/units/kitchen-units/:category",
    component: _145a2e71,
    name: "components-units-kitchen-units-specified-1"
  }, {
    path: "/components/units/kitchen-units",
    component: _145a2e71,
    name: "components-units-kitchen-units"
  }, {
    path: "/bedroom-builder/finishing-touches",
    component: _7984664a,
    name: "bedroom-builder-finishing-touches"
  }, {
    path: "/bedroom-builder/matching-accessories",
    component: _7984664a,
    name: "bedroom-builder-matching-accessories"
  }, {
    path: "/bedroom-builder/units",
    component: _7984664a,
    name: "bedroom-builder-units"
  }, {
    path: "/bedroom-builder",
    component: _7984664a,
    name: "bedroom-builder"
  }, {
    path: "/bedrooms/:key/:value",
    component: _2299dfd3,
    name: "bedrooms-specific"
  }, {
    path: "/bedrooms/:key",
    component: _56f7752b,
    name: "bedrooms-specific"
  }, {
    path: "/bedrooms",
    component: _2299dfd3,
    name: "bedrooms"
  }, {
    path: "/bedroom/:id",
    component: _563a5f7d,
    name: "bedroom"
  }, {
    path: "/kitchen-builder/finishing-touches",
    component: _4dcbbd13,
    name: "kitchen-builder-finishing-touches"
  }, {
    path: "/kitchen-builder/matching-accessories",
    component: _4dcbbd13,
    name: "kitchen-builder-matching-accessories"
  }, {
    path: "/kitchen-builder/units",
    component: _4dcbbd13,
    name: "kitchen-builder-units"
  }, {
    path: "/kitchen-builder",
    component: _4dcbbd13,
    name: "kitchen-builder"
  }, {
    path: "/new-password",
    component: _bd634d62,
    name: "new-password"
  }, {
    path: "/kitchens/:key/:value",
    component: _698e5a0b,
    name: "kitchens-specific"
  }, {
    path: "/kitchens/:key",
    component: _56f7752b,
    name: "kitchens-specific"
  }, {
    path: "/kitchens",
    component: _698e5a0b,
    name: "kitchens"
  }, {
    path: "/kitchen/:id",
    component: _85120496,
    name: "kitchen"
  }, {
    path: "/activation",
    component: _115548ca,
    name: "activation"
  }, {
    path: "/",
    component: _115548ca,
    name: "home"
  }, {
    path: "/404",
    component: _2a437068,
    name: "404"
  }, {
    path: "/Account",
    component: _5aaa5fbe,
    name: "Account"
  }, {
    path: "/BedroomBuilder",
    component: _7984664a,
    name: "BedroomBuilder"
  }, {
    path: "/BedroomRange",
    component: _563a5f7d,
    name: "BedroomRange"
  }, {
    path: "/Bedrooms",
    component: _2299dfd3,
    name: "Bedrooms"
  }, {
    path: "/Blog",
    component: _263d0644,
    name: "Blog"
  }, {
    path: "/BlogPost",
    component: _69ced2c4,
    name: "BlogPost"
  }, {
    path: "/Cms",
    component: _6feb273d,
    name: "Cms"
  }, {
    path: "/CustomerStories",
    component: _56d2b889,
    name: "CustomerStories"
  }, {
    path: "/CustomerStory",
    component: _6c0140aa,
    name: "CustomerStory"
  }, {
    path: "/Error",
    component: _e94d0d08,
    name: "Error"
  }, {
    path: "/Guide",
    component: _081d6f20,
    name: "Guide"
  }, {
    path: "/Guides",
    component: _f439329a,
    name: "Guides"
  }, {
    path: "/Home",
    component: _115548ca,
    name: "Home"
  }, {
    path: "/KitchenBuilder",
    component: _4dcbbd13,
    name: "KitchenBuilder"
  }, {
    path: "/KitchenRange",
    component: _85120496,
    name: "KitchenRange"
  }, {
    path: "/Kitchens",
    component: _698e5a0b,
    name: "Kitchens"
  }, {
    path: "/NewPassword",
    component: _bd634d62,
    name: "NewPassword"
  }, {
    path: "/NotFound",
    component: _56f7752b,
    name: "NotFound"
  }, {
    path: "/OrderConfirmation",
    component: _50af5a97,
    name: "OrderConfirmation"
  }, {
    path: "/Page",
    component: _478a714b,
    name: "Page"
  }, {
    path: "/PDP-Component",
    component: _47bf8ac0,
    name: "PDP-Component"
  }, {
    path: "/PLP-Categories",
    component: _145a2e71,
    name: "PLP-Categories"
  }, {
    path: "/PLP-Filters",
    component: _4e721e76,
    name: "PLP-Filters"
  }, {
    path: "/PLP-Gallery",
    component: _02566e4d,
    name: "PLP-Gallery"
  }, {
    path: "/Register",
    component: _4310bd42,
    name: "Register"
  }, {
    path: "/Regular",
    component: _6c631610,
    name: "Regular"
  }, {
    path: "/Search",
    component: _5696e6e4,
    name: "Search"
  }, {
    path: "/Sitemap",
    component: _109e6ea9,
    name: "Sitemap"
  }, {
    path: "/Support",
    component: _30b224c3,
    name: "Support"
  }, {
    path: "/Wishlist",
    component: _32a2c781,
    name: "Wishlist"
  }, {
    path: "/*",
    component: _34b16273,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
