
import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const getNavCommand = {
  execute: async (context: VsfContext) => {
    
    Logger.debug('[Winter CMS]: Get Nav');
    const data = await context.$magento.api.getNav();

    Logger.debug('[Result]:', { data });
    return data;
  },
};