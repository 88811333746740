














































import { useContent } from '~/composables';
import VueSlickCarousel from 'vue-slick-carousel'

import {
    SfButton,
    //SfTopBar
} from '@storefront-ui/vue';
// import { useTopBar } from './useTopBar';

import {
    // computed,
    // ref,
    defineComponent,
    // useRouter,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

// import {
//     WntrTrustPilot
// } from '~/modules/GraphQL/types';

export default defineComponent({
    components: {
        VueSlickCarousel,
        CurrencySelector: () => import('../CurrencySelector.vue'),
        StoreSwitcher: () => import('../StoreSwitcher.vue'),
        // SfTopBar,
        SfButton,
    },
    setup() {
    
        const { 
            getTrustPilotData
        } = useContent();

        // const wntrTrustPilot = ref<WntrTrustPilot | null>(null);

        // useFetch(async () => {
        //     wntrTrustPilot.value = await getTrustPilotData();
        // })
        var wntrTrustPilotRef = getTrustPilotData();

        return {
            wntrTrustPilotRef,
            // hasCurrencyToSelect,
            // hasStoresToSelect,
            settings: {
                arrows: false,
                dots: false,
                // dotsClass: "slick-dots custom-dot-class",
                // edgeFriction: 0.35,
                infinite: true,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: false,
                speed: 500,
                autoplaySpeed: 5000,
                cssEase: "ease-in-out",
                // prevArrow: '<i class="fa-solid fa-chevron-left"></i>',
                // nextArrow: '<i class="fa-solid fa-chevron-right"></i>',
                // prevArrow: '',
                // nextArrow: '',
                centerMode: true,
                centerPadding: '0px',
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 1,
                            autoplay: true
                        }
                    }
                ]
            }
        };
    },
    async fetch() {
        this.wntrTrustPilot = await this.wntrTrustPilotRef

        this.processWntrTrustPilot()
    },
    data() {
        return {
            wntrTrustPilot: {},
            overall_stars_rounded: 0
        }
    },
    methods: {
        getRoundedScore(stars) {
            return  Math.round(stars).toFixed(1).split('.').join("")
        },
        processWntrTrustPilot () {
            this.overall_stars_rounded = this.wntrTrustPilot.overall_stars.split('.').join("")
        }
    }
});

