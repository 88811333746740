

import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';

export const getPaymentStatusCommand = {
  execute: async (context: VsfContext, params) => {
    Logger.debug('[Winter CMS]: Get Payment Status', { params });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const data = await context.$magento.api.getPaymentStatus(params?.token, params?.customQuery);

    Logger.debug('[Result]:', { data });
    return data;
  },
};
