









import {
    defineComponent,
    // ref,
    // useContext,
    // onMounted,
    // useFetch,
} from '@nuxtjs/composition-api';

export default defineComponent({
    name: 'OnlinecCheck',
    components: {
        
    },
    // setup() {
        
    // },
    // data() {
        
    // },
    props: {
        padding: {
            type: String,
            default: ''
        },
        margin: {
            type: String,
            default: ''
        },
        background: {
            type: String,
            default: ''
        }
    },
    // computed: {
        
    // }
});
